.userpanel{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-logo-container{
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-title{
  background-color: var(--bg-color-alt);
  color: var(--font-color);
  width: 95%;
  text-align: center;
  padding: 5px;
  max-width: 700px;
  border: 1px solid gray;
  font-size: 18px;
}
.user-logo{
  width: 80%;
  cursor: pointer;
  max-height: 200px;
  max-width: 250px;
  margin: 30px;
}
.course-item{
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: 10px;
  display: flex;
  flex-direction: c;
  align-items: center;
  margin: 10px;
  border: 1px solid gray;
}
.course-item-title,
.course-item-desc{
  text-align: center;
}
.course-item button{
  padding: 10px;
}
.user-course-container{
  width: 95%;
  display: flex;
  flex-direction: column;
  min-height: max-content;
  justify-self: center;
  background-color: var(--bg-color);
  border: 1px solid grey;
}
.user-course-buttons{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
.user-footer{
  background-color: var(--bg-color-alt);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  border: 1px solid gray;
}
.user-footer button{
  padding: 10px;
}
.user-courses-nav,
.user-footer,
.user-course-container{
  max-width: 700px;
}
.user-footer{
  padding: 10px;
  width: 95%;
}