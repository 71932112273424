
/* home logo section styling */
.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
button{
  border: 3px solid var(--button-border-color);
  border-radius: var(--border-radius);
  background-color: var(--button-color);
  color: var(--font-color);
  font-family: Oswald-bold, sans-serif;
  transition: background-color 0.5s;
}
button:hover{
  cursor: pointer;
  background-color: var(--button-hover);
}
button:active{
  background-color: var(--button-pressed);
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.home-logo{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1.5s ease-in 0s 1 fadeIn;
}
.logo{
  width: 85%;
  cursor: pointer;
}
@media  screen and (max-width: 700px) {
  .home-logo,
  .logo{
    max-width: 300px;
  }
}
@media screen and (min-width: 701px){
  .home-logo,
  .logo{
    max-width: 500px;
  }
}
/* enrollment section styling */
.enroll{
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}
.enroll-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.course-buttons-container{
  display: none;
  justify-content: center;
  width: 100%;
  align-items: center;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  animation-name: fadein;
  animation-duration: 2s; /* Change duration as needed */
  animation-fill-mode: forwards;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeout {
  animation-name: fadeout;
  animation-duration: 1s; /* Change duration as needed */
  animation-fill-mode: forwards;
}
.course{
  background-color: var(--bg-color);
  height: max-content;
  width: 90%;
  max-width: 700px;
  color: var(--font-color);
  padding: 20px;
  border-radius: var(--border-radius);
  display: grid;
  border: 1px solid grey;
}
.course-name{
  background-color: var(--bg-color-alt);
  padding: 10px;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
}
.course-desc{
  padding: 15px;
  align-items: center;
  justify-self: center;
  text-align: center;
  font-size: 18px;
}
.course-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-button{
  justify-self: center;
  margin: 4px;
  padding: 5px;
  width: 100%;
  max-width: 150px;
  font-size: 1rem;
}
/* login notice section */
.login-notice{
  height: 180px;
  width: 90%;
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  background-color: var(--bg-color);
  margin-bottom: 30px;
  gap: 20px;
  border-radius: var(--border-radius);
  border: 1px solid grey;
}
.login-text{
  color: var(--font-color);
  background-color: var(--bg-color-alt);
  padding: 15px;
  border-radius: var(--border-radius);
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  font-family: Oswald-regular;
}
.login-button{
  height: 50px;
  width: 100%;
  font-size: 0.9rem;
  max-width: 120px;
}

.footer{
  margin-top: 10px;
}
.about-me{
  background-color: var(--bg-color);
  color: var(--font-color);
  max-width: 700px;
  width: 90%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  border: 1px solid grey;
  padding: 20px;
  border-radius: var(--border-radius);
}
.about-title{
  text-align: center;
  font-size: 20px;
  background-color: var(--bg-color-alt);
  width: 90%;
  font-weight: 500;
  padding: 10px;
  border-radius: var(--border-radius);
}
.about-text{
  text-align: center;
  margin: 0px 10px 20px 10px;
  padding: 10px;
  font-size: 20px;

}
.testimonials{
  display: flex;
  color: var(--font-color);
  flex-direction: column;
  gap: 20px;
  background-color: var(--bg-color);
  padding: 30px;
  border: 1px solid grey;
  border-radius: var(--border-radius);
  margin-top: 30px;
  font-size: 1.5rem;
  max-width: 700px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.testimonial-title{
  background-color: var(--bg-color-alt);
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: var(--border-radius);
}
.testimonial-text{
  max-width: 100%;
  border-radius: 10px;
  text-align: center;
}
.testimonial-credit{
  text-align: center;
}
.quote{
  font-weight: 700;
  font-size: 30px;
  color: grey;
}