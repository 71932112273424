.checkout{
  display: flex;
  min-height: 100vh;
  max-height: fit-content;
  flex-direction: column;
  height: 100%;
  align-self: center;
  width: 100%;
}
.checkout-form{
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  width: 95%;
  padding: 10px;
  align-self: center;
  font-family: Oswald-regular, sans-serif;
  max-width: 700px;
}
.checkout-form-items{
  background-color: var(--bg-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px;
  gap: 10px;
  color: var(--font-color);
  border: 1px solid grey;
  padding: 20px;
  border-radius: 5px;
}
.checkout-form-items input{
  padding-left: 5px;
  border: 3px solid black;
  height: 50px;
  background-color: var(--bg-color-alt);
  color: var(--font-color)
}
.checkout-form-items label{
  display: flex;
  align-items: center;
}
.checkout-cart{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--font-color);
  border: 1px solid grey;
  background-color: var(--bg-color);
  width: 95%;
  align-self: center;
  padding: 12px;
  max-width: 700px;
  font-size: 18px;
}
.checkout-cart-name{
  text-align: center;
  padding: 5px;
}
.checkout-notice{
  text-align: center;
  width: 50%;
  color: var(--font-color);
  border: 1px solid grey;
  padding: 8px;
  border-radius: 5px;
}
.checkout-form-buttons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.checkout-form-buttons button:not(.pay-button){
  height: 50px;
  width: 60px;
}
.checkout-logo-container{
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
.pay-button{
  width: 150px;
  height: 50px;
}
.checkout-owns-course{
  height: 100vh;
  width: 100vw;
  display: none;
  background-color: var(--bg-color);
  color: var(--font-color);
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.checkout-owns-course-button{
  height: 50px;
  width: 150px;
  padding: 5px;
  animation: 1s ease-in 0s 1 fadeIn;
}
.checkout-owns-course-notice{
  animation: 1s ease-in 0s 1 fadeIn;
}
.apply-promo{
  height: max-content;
  padding: 5px;
}
.promo-container{
  gap: 5px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}
.course-desc{
  display: flex;
  justify-content: center;
  color: var(--font-color);
}