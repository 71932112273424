@keyframes jump{
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(-40%);
  }
  100%{
    transform: translateY(0);
  }
}
.to-top-button{
  height: 35px;
  width: 35px;
  right: 5px;
  position: fixed;
  bottom: 5px;
  color: var(--button-color);
  background-color: var(--button-border-color);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 1s;
  z-index: 2;
}
.to-top-button:hover{
  transform: translateY(-20%);
}
/* set static background */
.home-background-wrapper{
  position: fixed;
  z-index: -1;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  max-height: 100vh;
  height: 100vh;
}
.home-background{
  width: 100%;
}
@media screen and (min-width: 701px) {
  .home-background{
    object-fit:cover;
    object-position: 0px 0px;
  }
}
.app{
  height: max-content;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* footer styling */
.footer{
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
  margin: 5px;
  gap: 7px;
  height: 20vh;
  font-size: 18px;
}
.social-media-container{
  padding: 5px;
  border-radius: var(--border-radius);
  width: max-content;
  display: flex;
  border: 1px solid grey;
  position: fixed;
  bottom: 0;
  gap: 10px;
  background-color: var(--bg-color);
  z-index: 2;
}

.social-media-container *{
  height: 45px;
  width: 45px;
  padding: 5px;
}
.social-media-container img{
  transition: transform 1s;
}
.social-media-container img:hover{
  cursor: pointer;
  transform: rotate(10deg);
}