.checkout-complete{
  color: var(--font-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  align-self: center;
  gap: 20px;
  height: 100vh;
}
.checkout-complete-title{
  background-color: var(--bg-color);
  border: 1px solid grey;
  max-width: 400px;
  width: 80%;
  padding: 30px;
  border-radius: var(--border-radius);
}
.checkout-complete-view-course{
  max-width: 400px;
  height: 50px;
  width: 80%;
}