*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
/* font imports */
@font-face {
  font-family: Oswald-Bold;
  src: url(../assets/fonts/Oswald-Bold.ttf);
}
@font-face {
  font-family: Oswald-Light;
  src: url(../assets/fonts/Oswald-Light.ttf);
}
@font-face {
  font-family: Oswald-Regular;
  src: url(../assets/fonts/Oswald-Regular.ttf);
}
body {
  margin: 0;
  font-family: Oswald-regular,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
html{
  scroll-behavior: smooth;
}
/* scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.8);
}
::-webkit-scrollbar-thumb {
  background: #464646;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* variable declarations */
:root{
  --bg-color: rgba(39,39,39,0.9);
  --bg-color-alt: rgba(70,69,69,0.9);
  --bg-fade: rgba(0,0,0,0.5);
  --font-color: rgb(224, 224, 224);
  --font-color-alt: #969595;
  --button-color: #a00;
  --button-pressed: rgb(95, 0, 0);
  --button-hover: rgb(119, 4, 4);
  --button-border-color: rgb(224, 224, 224);
  --border-radius: 5px;
}
.red{
  font-size: 25px;
  color: rgb(212, 3, 3);
}