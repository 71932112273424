/* video container */
.current-course-video{
  align-items: center;
  display: none;
  background-color: var(--bg-color);
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 5px;
  position: fixed;
  width: 100vw;
  gap: 10px;
}
.course-video{
  background-color: black;
  max-height: 80%;
  min-width: 100px;
  max-width: 500px;
  width: 95%;
  border: 2px solid grey;
}
.close-video-button{
  border-radius: var(--border-radius);
  height: 50px;
  margin: 10px;
  padding: 10px;
  width: 100px;
}
/* course content */
.course-content-logo{
  height: 150px;
  width: 250px;
  cursor: pointer;
  align-self: center;
  justify-self: center;
}
.course-content{
  height: max-content;
  max-width: 700px;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.week-item{
  width: 100%;
}
.week-title{
  cursor: pointer;
  text-align: center;
  border: 1px solid grey;
  width: 100%;
  background-color: var(--bg-color);
  padding: 10px;
  font-size: 17px;
}
.day-title{
  text-align: center;
  cursor: pointer;
  transition: background-color 1s;
  padding: 10px;
  font-size: 16px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(54, 1, 1);
}
.day-title:hover{
  background-color: var(--bg-color);
}
.day-item{
  width: 100%;
  text-align: center;
  transition: background-color 1s;
  cursor: pointer;
  padding: 5px;
}
.day-item:hover{
  background-color: var(--bg-color);
}
.week-container{
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.week-wrapper{
  display: none;
  flex-direction: column;
  border: 1px solid grey;
  background-color: rgba(0,0,0,0.8);
}
.day-content{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notice-container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: var(--bg-color);
  height: max-content;
  justify-self: center;
  color: var(--font-color);
}