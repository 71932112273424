/* static home logo styling  */
.home-logo-register{
  position: fixed;
  /* puts background image behind everything else */
  z-index: -998;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* register form styling */
.register{
  height: max-content;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-form{
  height: max-content;
  width: 80%;
  padding: 20px;
  display: grid;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 0.2fr 0.2fr 0.2fr 0.2fr 1fr;
  grid-template-areas: 
  "close close" 
  "email email" 
  "password password"
  "passwordconf passwordconf"
  "messages messages"
  "registerbutton registerbutton";
  max-width: 500px;
  border: 1px solid grey;
}
.email-register,
.password-register,
.password-conf-register{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  color: var(--font-color);
  margin: 30px;
}
.register-form input{
  height: 30px;
  width: 100%;
  padding-left: 10px;
  padding: 3px;
  border: 2px solid black;
  font-family: Oswald-regular, sans-serif;
  background-color: var(--bg-color-alt);
  color: var(--font-color);
  font-size: 16px;
}
.email-register{
  grid-area: email;
}
.password-register{
  grid-area: password;
}
.password-conf-register{
  grid-area: passwordconf;
}
.register-messages{
  grid-area: messages;
  color: red;
  grid-area: messages;
  text-align: center;
}
#signup-button{
  grid-area: registerbutton;
  height: 60px;
  width: 90%;
  max-width: 200px;
  margin: 20px;
}
.reg-comp{
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100vh;
  justify-content: center;
}